import {ComponentsContext} from '@oracle-cx-commerce/react-ui/contexts';
import Provider from '@oracle-cx-commerce/react-components/provider';
import React from 'react';
import {fetchSite} from '@oracle-cx-commerce/fetchers/site';
import {useSiteFetcher} from '@oracle-cx-commerce/fetchers/site/hooks';
import Page from '../page';

import {GlobalProvider} from '../contexts/global';

export const fetchers = [fetchSite];

const Root = ({components, store}) => {
  useSiteFetcher(store);

  return (
    <ComponentsContext.Provider value={components}>
      <Provider store={store}>
        <GlobalProvider>
          <Page />
        </GlobalProvider>
      </Provider>
    </ComponentsContext.Provider>
  );
};

export default Root;
