import {useEffect, useCallback, useContext} from 'react';
import {StoreContext} from '@oracle-cx-commerce/react-ui/contexts';
import {useSelector} from '@oracle-cx-commerce/react-components/provider';
import {getSite, getEndpointStatus} from '@oracle-cx-commerce/commerce-utils/selector';

export const useSite = () => {
  const site = useSelector(getSite);
  const {inProgress} = useSelector(getEndpointStatus)?.getSite || {};
  const {action} = useContext(StoreContext);

  const loadSite = useCallback(async () => {
    if (inProgress) return;
    if (Object.keys(site).length) return;

    const siteId = 'siteUS';
    action('getSite', {siteId});
  }, [action, inProgress, site]);

  useEffect(() => {
    loadSite();
  }, [loadSite]);

  return site;
};
