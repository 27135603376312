import {useContext, useEffect} from 'react';
import {StoreContext} from '@oracle-cx-commerce/react-ui/contexts';
import {useSelector} from '@oracle-cx-commerce/react-components/provider';
import {getOrganizationCurrentProfile} from '@oracle-cx-commerce/core-commerce-reference-store/src/plugins/selectors';
import {
  getCurrentProfileId,
  isAuthenticated,
  getSessionContext,
  getEndpointStatus,
  getCurrentProfile
} from '@oracle-cx-commerce/commerce-utils/selector';
import {getOrganizationsResponse} from '../selectors';

const LIMIT_ITEMS_RESQUEST = 150;
export const useOrganizations = (setFinishLoadOrganizations, finishLoadOrganizations) => {
  const {action} = useContext(StoreContext);
  const profileId = useSelector(getCurrentProfileId);
  const token = useSelector(getSessionContext)?.token;
  const organizations = useSelector(getOrganizationsResponse);
  const {inProgress} = useSelector(getEndpointStatus)?.getAllOrganizations || {};
  const isUserLoggedIn = useSelector(isAuthenticated);
  const organizationCurrent = useSelector(getOrganizationCurrentProfile);
  const currentProfile = useSelector(getCurrentProfile);

  useEffect(() => {
    const setOrganizations = async () => {
      if (!isUserLoggedIn) return;
      if (!profileId || profileId === 'anonymous' || !token) return;
      if (inProgress) return;

      const {length} = organizations?.items || [];
      if (length < organizations.totalResults || !organizations?.totalResults)
        await action('getAllOrganizationsProfile', {profileId, token, offset: length, limit: LIMIT_ITEMS_RESQUEST});
      else setFinishLoadOrganizations(true);
    };

    if (!finishLoadOrganizations) setOrganizations();
  }, [
    action,
    inProgress,
    isUserLoggedIn,
    organizations,
    profileId,
    setFinishLoadOrganizations,
    token,
    finishLoadOrganizations
  ]);

  useEffect(() => {
    const cleanOrganizations = () => {
      const {length} = organizations?.items || [];
      if (!isUserLoggedIn && length) {
        action('cleanOrganization');
        setFinishLoadOrganizations(false);
      }
    };
    cleanOrganizations();
  }, [action, isUserLoggedIn, organizations, setFinishLoadOrganizations]);

  useEffect(() => {
    const updateOrganizationsLocal = async () => {
      if (!finishLoadOrganizations) return;

      const {length} = organizations?.items || [];
      for (let offset = 0; offset < length; offset += LIMIT_ITEMS_RESQUEST) {
        await action('setOrganizationsProfileLocal', {
          profileId,
          token,
          offset,
          limit: LIMIT_ITEMS_RESQUEST
        });
      }
    };

    updateOrganizationsLocal();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [finishLoadOrganizations]);

  useEffect(() => {
    try {
      const {currentOrganization} = currentProfile;

      const setCurrentOrganization = async () => {
        await action('getOrganizationCurrent', {organizationId: currentOrganization});
      };

      if (currentOrganization && currentOrganization !== organizationCurrent?.id) setCurrentOrganization();
    } catch (err) {
      console.error('TAG - setCurrentOrganization - ', err);
    }
  }, [action, currentProfile, organizationCurrent]);
};
